.custom-table {
  border-spacing: 0;
  border: 1px solid #000000;
}

.custom-table th,
.custom-table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #212020b3;
  border-right: 1px solid #212020b3;
  width: 20%; /* Add this line */
}

.custom-table .first-column {
  min-width: 800px;  /* adjust as needed */
  white-space: pre-wrap;
  word-wrap: break-word;
}

.custom-table .second-column {
  max-width: 300px;  /* adjust as needed */
  white-space: pre-wrap;
  word-wrap: break-word;
}


table {
    border-spacing: 0;
    border: 1px solid #000000;
    table-layout: fixed;
    /* width: 100%; */
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table th,
  table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #212020b3;
    border-right: 1px solid #212020b3;
    position: relative;
    /* width: 20%; */
  }
  table th:last-child,
  table td:last-child {
    border-right: 0;
  }
  table tr:nth-child(even) {
    background-color: #f1f1f1c9;
  }
  
  table th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid #22543d;
  }
  table th.sort-desc::before {
    border-top: 5px solid #22543d;
  }
  
  .chart-holder{
    display:flex;
    width: 100%;
    max-width:1800px;
  }

  .chart{
    flex: 1;
    margin: 10px;
  }

  .chart:first-child {
    margin-right: 20px;
  }

  .table-holder {
    width: 65%;
    overflow-x: scroll;
  }

  .global-filter{
    display: flex;
    flex-direction: column;
    height: 50px;
    width: 50%;
    margin: 20px;
    margin-bottom: 50px;
  }

.bio-holder {
  display:flex;
  align-items: top;
  margin-bottom: 30px;
  
}

.profile-image-M {
  width: 500px;
  height: 370.9px;
  margin-right: 30px;
  margin-top: 24px;
}

.profile-image-A {
  width: 500px;
  height: 333.7px;
  margin-right: 30px;
  margin-top: 24px;

}


.insight-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
}

.insight-button {
  margin: 30px
}

.insight {
  margin: 20px;
  width: 90%;
  align-self: center;
}

.login-container {
  
}